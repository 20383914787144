/**
 * @module ContentListFetcher
 */
import { getMagnoliaItem } from '@io/web-tools-io/dist/utils/helpers/magnolia/getMagnoliaItem';
import {
    ENDPOINT_WORKSPACE_MAP,
    MAGNOLIA_ENDPOINTS,
    MGNL_ENV_VARS,
    WEB_DISTRIBUTION_PLATFORM_UUID,
  } from '../constants';

/**
 * Convenience function to fetch the media elements for the FindsContentList.
 *
 * @param {object} params - The function params object.
 * @param {object} [params.collectDataFrom] - Data object of attributes and field values specifying the source of the content.
 * @param {number} [params.limitFilter] - Optional limit filter number value used for the delivery request.
 * @param {object} [params.metadata] - Optional object containing Magnolia's system and templating fields.
 * @param {string} params.sbOnMobile - Class name to apply to component wrapper for mobile screen sizes.
 * @param {string} params.sbOnTabletAndUp - Class name to apply to component wrapper for tablet and up screen sizes.
 * @param {type} [params.type] - Optional type of field to fetch.
 *
 * @returns {object} The object that contains the data for the FindsContentList.
 */
async function fetchFindsContentList({
  collectDataFrom = { field: 'articles' },
  limitFilter,
  metadata,
  sbOnMobile,
  sbOnTabletAndUp,
  type,
}) {
  const normalize = (data) => {
    if (data && data.length) {
      const normalized = {
        '@nodes': [],
      };
      data.forEach((item) => {
        normalized['@nodes'].push(item['@id']);
        normalized[item['@id']] = {
          '@id': item['@id'],
          '@name': item['@name'],
          '@nodes': [],
          '@nodeType': item['@nodeType'],
          '@path': item['@path'],
          '@workspace': item['@workspace'],
          article: item['@id'],
          backgroundSize: 'cover',
          linkTitle: 'Read more',
          'mgnl:created': item['mgnl:created'],
          'mgnl:lastModified': item['mgnl:lastModified'],
          'mgnl:template': 'lifechurch:components/opencontentlistarticle',
          preload: item,
          target: '_blank',
        };
      });
      return normalized;
    }
    return null;
  };

  const endpointName =
    collectDataFrom.field === 'articles'
      ? 'finds-articles/v1'
      : 'finds/journey';
  const apiEndpoint = `/.rest/delivery/${endpointName}`;
  let queryString = '?';

  if (collectDataFrom.field === 'articles') {
    const { filterByStartDate, filterByType } = collectDataFrom;
    const filterByTag = collectDataFrom['mgnl:tags'];

    if (filterByTag?.length) {
      queryString += `mgnl:tags[like]=%${filterByTag.join('|')}%&`;
    }
    if (filterByStartDate) {
      queryString += `startDate[gte]=${filterByStartDate}&`;
    }
    if (filterByType?.length) {
      queryString += `type[like]=%${filterByType.join('|')}%&`;
    }
    queryString += `orderBy=startDate desc&`;

    if (limitFilter && !Number.isNaN(Number(limitFilter))) {
      queryString += `limit=${limitFilter}`;
    }
  }

  const findsData = await getMagnoliaItem({
    caller:
      'src/helpers/dataFetchers/findsContentListFetcher.js > fetchContentList',
    fallbackdata: [],
    mgnlEnvVars: MGNL_ENV_VARS,
    path: encodeURI(`${apiEndpoint}${queryString}`),
    workspaceMap: ENDPOINT_WORKSPACE_MAP,
  });

  /* istanbul ignore next */
  const data = findsData?.results ?? [];

  const normalized = normalize(data);

  const contentListMetadata = metadata || {};
  contentListMetadata['mgnl:template'] =
    'lifechurch:components/opencontentlist';

  /**
   * Ignoring next for normalized and metadata fallbacks, as the fallback values
   * should always be set properly above, but are left here for precaution
   * to guard against rogue edge cases.
   */
  /* istanbul ignore next */
  return {
    items: normalized || [],
    metadata: contentListMetadata || {},
    sbOnMobile,
    sbOnTabletAndUp,
    type,
  };
}

export { fetchFindsContentList };
